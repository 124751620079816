form {
    label {
        display: inline-block;
    }
    input[type="radio"] {
        position: absolute;
        margin-top: 0.4rem;
        margin-left: -1.25rem;
    }
    .form-check {
        font-weight: 400;
        line-height: 1.5;
        position: relative;
        display: block;
        padding-left: 1.25rem;
        margin-bottom: 2px;
    }
    .mb-1 {
        margin-bottom: 0.25rem !important;
    }
    label.mb-1 {
        font-size: 17px;
    }
    .bordered {
        margin-top: 10px;
        margin-bottom: 6px;
        border: 2px solid $color-secondary;
        padding: 10px 20px;
        & p {
            margin-bottom: 0px;
        }
    }
    .form-group .form-check:last-of-type {
        margin-bottom: 0;
    }
    .form-group .text {
        & p {
            line-height: 1.3;
            font-size: 16px;
        }
        & ul {
            margin-bottom: 0.5em;
            margin-top: 0.5em;
            padding-left: 1.6em;
            & li {
                padding-left: 1em;
                padding-bottom: 0.4em;
            }
        }
    }
}
