.grid {
    display: flex;
    &-container {
        overflow: hidden;
        width: 100%;
        &--no-overflow {
            overflow: visible;
        }
    }
    &--wrap {
        flex-wrap: wrap;
        &-laptop {
            @media(max-width: $laptop) {
                flex-wrap: wrap;
            }
        }
        &-tablet {
            @media(max-width: $tablet) {
                flex-wrap: wrap;
            }
        }
        &-phablet {
            @media(max-width: $phablet) {
                flex-wrap: wrap;
            }
        }
        &-phone {
            @media(max-width: $phone) {
                flex-wrap: wrap;
            }
        }
    }
    &--nowrap {
        flex-wrap: nowrap;
    }
    &--column {
        flex-direction: column;
    }
    &--row {
        flex-direction: row;
    }
    &--align-center {
        align-items: center;
    }
    &--align-start {
        align-items: flex-start;
    }
    &--align-end {
        align-items: flex-end;
    }
    &--justify-space-b {
        justify-content: space-between;
    }
    &--justify-end {
        justify-content: flex-end;
    }
    &--justify-center {
        justify-content: center;
        &-laptop {
            @media(max-width: $laptop) {
                justify-content: center;
            }
        }
        &-tablet {
            @media(max-width: $tablet) {
                justify-content: center;
            }
        }
        &-phablet {
            @media(max-width: $phablet) {
                justify-content: center;
            }
        }
        &-phone {
            @media(max-width: $phone) {
                justify-content: center;
            }
        }
    }
    &__item {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        max-width: 100%;

        &--w-auto {
            flex-basis: auto;
        }
        &--w-custom {
            flex-basis: 210px;
        }
        &--w-custom-2 {
            flex-basis: 270px;
        }
        &--w-20 {
            flex-basis: 20%;
            max-width: 20%;
        }
        &--w-25 {
            flex-basis: 25%;
            max-width: 25%;
        }
        &--w-33 {
            flex-basis: 33.33333%;
            max-width: 33.33333%;
        }
        &--w-50 {
            flex-basis: 50%;
            max-width: 50%;
        }
        @media(max-width: $laptop) {
            &--w-auto-laptop {
                flex-basis: auto;
            }
            &--w-20-laptop {
                flex-basis: 20%;
                max-width: 20%;
            }
            &--w-25-laptop {
                flex-basis: 25%;
                max-width: 25%;
            }
            &--w-33-laptop {
                flex-basis: 33.33333%;
                max-width: 33.33333%;
            }
            &--w-50-laptop {
                flex-basis: 50%;
                max-width: 50%;
            }
            &--w-100-laptop {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        @media(max-width: $tablet) {
            &--w-auto-tablet {
                flex-basis: auto;
            }
            &--w-20-tablet {
                flex-basis: 20%;
                max-width: 20%;
            }
            &--w-25-tablet {
                flex-basis: 25%;
                max-width: 25%;
            }
            &--w-33-tablet {
                flex-basis: 33.33333%;
                max-width: 33.33333%;
            }
            &--w-50-tablet {
                flex-basis: 50%;
                max-width: 50%;
            }
            &--w-100-tablet {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        @media(max-width: $phablet) {
            &--w-auto-phablet {
                flex-basis: auto;
            }
            &--w-20-phablet {
                flex-basis: 20%;
                max-width: 20%;
            }
            &--w-25-phablet {
                flex-basis: 25%;
                max-width: 25%;
            }
            &--w-33-phablet {
                flex-basis: 33.33333%;
                max-width: 33.33333%;
            }
            &--w-50-phablet {
                flex-basis: 50%;
                max-width: 50%;
            }
            &--w-100-phablet {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        @media(max-width: $phone) {
            &--w-auto-phone {
                flex-basis: auto;
            }
            &--w-20-phone {
                flex-basis: 20%;
                max-width: 20%;
            }
            &--w-25-phone {
                flex-basis: 25%;
                max-width: 25%;
            }
            &--w-33-phone {
                flex-basis: 33.33333%;
                max-width: 33.33333%;
            }
            &--w-50-phone {
                flex-basis: 50%;
                max-width: 50%;
            }
            &--w-100-phone {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        &--no-grow {
            flex-grow: 0;
            &-laptop {
                @media(max-width: $laptop) {
                    flex-grow: 0;
                }
            }
            &-tablet {
                @media(max-width: $tablet) {
                    flex-grow: 0;
                }
            }
            &-phablet {
                @media(max-width: $phablet) {
                    flex-grow: 0;
                }
            }
            &-phone {
                @media(max-width: $phone) {
                    flex-grow: 0;
                }
            }
        }
        &--grow {
            flex-grow: 1;
            &-laptop {
                @media(max-width: $laptop) {
                    flex-grow: 1;
                }
            }
            &-tablet {
                @media(max-width: $tablet) {
                    flex-grow: 1;
                }
            }
            &-phablet {
                @media(max-width: $phablet) {
                    flex-grow: 1;
                }
            }
            &-phone {
                @media(max-width: $phone) {
                    flex-grow: 1;
                }
            }
        }
        &--ml-auto {
            margin-left: auto;
        }
        &--mr-auto {
            margin-right: auto;
        }
        &--mt-auto {
            margin-top: auto;
        }
        &--mb-auto {
            margin-bottom: auto;
        }
        &--hidden {
            display: none;
            &-laptop {
                @media(max-width: $laptop) {
                    display: none;
                }
            }
            &-tablet {
                @media(max-width: $tablet) {
                    display: none;
                }
            }
            &-phablet {
                @media(max-width: $phablet) {
                    display: none;
                }
            }
            &-phone {
                @media(max-width: $phone) {
                    display: none;
                }
            }
        }
    }
}
