/* screen reader only */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.hidden {
    display: none;
}
/* end of screen reader only */

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    outline: none;
    color: currentColor;
    flex-shrink: 1;
}
html,
body {
    font-family: "Roboto Condensed", sans-serif;
    height: 100%;
    font-size: 16px;
    box-sizing: border-box;
    color: $color-primary;

    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    background-color: #f9f9f9;
}
.wrapper {
    max-width: 1140px;
    width: 96%;
    margin: 0 auto;
    @media (max-width: $desktop) {
        width: 92%;
    }
    @media (max-width: $laptop) {
        width: 90%;
    }
    @media (max-width: $tablet) {
        width: 88%;
    }
    @media (max-width: $phone) {
        width: 86%;
    }

    &--narrow {
        max-width: 980px;
    }
    &--narrow-2 {
        max-width: 870px;
    }
    &--wide {
        max-width: 100%;
    }
}

.site {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}
.main-header {
    flex-shrink: 0;
    background-color: $color-default;
}
.main-content {
    flex-grow: 1;
    flex-shrink: 0;
    background-color: $color-default;
    overflow: hidden;
}
.main-footer {
    flex-shrink: 0;
    background-color: $color-default;
}

.scrollable-content {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* margin bottom */
.mb {
    &-10 {
        margin-bottom: 10px;
    }
    &-15 {
        margin-bottom: 15px;
    }
    &-20 {
        margin-bottom: 20px;
    }
    &-25 {
        margin-bottom: 25px;
    }
    &-30 {
        margin-bottom: 30px;
    }
    &-40 {
        margin-bottom: 40px;
    }
    &-45 {
        margin-bottom: 45px;
    }
    &-50 {
        margin-bottom: 50px;
    }
    &-60 {
        margin-bottom: 60px;
    }
    &-65 {
        margin-bottom: 65px;
    }
    &-70 {
        margin-bottom: 70px;
    }
    &-80 {
        margin-bottom: 80px;
    }
    &-90 {
        margin-bottom: 90px;
    }
    &-100 {
        margin-bottom: 100px;
    }
    &-30,
    &-40,
    &-45,
    &-50 {
        @media (max-width: $phablet) {
            margin-bottom: 25px;
        }
    }
    &-60,
    &-65,
    &-70 {
        @media (max-width: $tablet) {
            margin-bottom: 50px;
        }
        @media (max-width: $phone) {
            margin-bottom: 40px;
        }
    }
    &-80,
    &-90,
    &-100 {
        @media (max-width: $tablet) {
            margin-bottom: 70px;
        }
        @media (max-width: $phone) {
            margin-bottom: 60px;
        }
    }
}
/* end of margin bottom */

// negative margin bottom
.negative-mb {
    &-60 {
        margin-bottom: -60px;
        position: relative;
        & + .section {
            padding-top: 60px;
        }
    }
}
// end of negative margin bottom

/* background color */
.bg-color {
    &--primary {
        background-color: $color-primary;
    }
    &--secondary {
        background-color: $color-secondary;
    }
    &--tertiary {
        background-color: $color-tertiary;
    }
    &--quaternary {
        background-color: $color-quaternary;
    }
    &--dark {
        background-color: $color-dark;
    }
    &--default {
        background-color: $color-default;
    }
    &--light {
        background-color: $color-light;
    }
    &--text {
        background-color: $color-text;
    }
    &--dropdown {
        background-color: $color-dropdown;
    }
    &--dropdown-border {
        background-color: $color-dropdown-border;
    }
    &--border {
        background-color: $color-border;
    }
    &--happy {
        background-color: $color-happy;
    }
    &--danger {
        background-color: $color-danger;
    }
}

/* end of background color */

/* text color */
.text-color {
    &--primary {
        color: $color-primary;
    }
    &--secondary {
        color: $color-secondary;
    }
    &--light {
        color: $color-light;
    }
    &--danger {
        color: $color-danger;
    }
    &--default {
        color: $color-default;
    }
}
/* end of text color */
.text-align {
    &--left {
        text-align: left;
    }
    &--right {
        text-align: right;
    }
    &--center {
        text-align: center;
        &-tablet {
            @media (max-width: $tablet) {
                text-align: center;
            }
        }
        &-phone {
            @media (max-width: $phone) {
                text-align: center;
            }
        }
    }
}

.icon {
    fill: currentColor;
}

.contact {
    font-size: 1.375em;
    display: flex;
    align-items: center;
    color: currentColor;
    @media (max-width: $phone) {
        font-size: 1.1em;
    }
    &__link {
        margin-right: 0.3125em;
        transition: 0.3s ease;
        display: flex;
        align-items: center;
        &:last-child {
            margin-right: 0;
        }
        &-text {
            display: inline-block;
        }
        .no-touch &:hover {
            opacity: 0.7;
        }
    }
    & .icon {
        width: 0.875em;
        height: 0.875em;
        margin-right: 0.6875em;
        flex-shrink: 0;
        &:last-child {
            margin-right: 0;
        }
    }
}

.section {
    &--with-bg {
        background-repeat: no-repeat;
        background-position: right bottom;
        &-homepage {
            background-position: bottom center;
        }
        @media (max-width: $laptop) {
            background-position: center bottom;
            &-homepage {
                @media (max-width: $phone) {
                    background-position: center bottom -30px;
                }
            }
        }
    }
    &__heading {
        color: $color-text;
        font-size: 28px;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 1.5em;
        @media (max-width: $tablet) {
            font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (768 - 320)));
        }
        &--less-mb {
            margin-bottom: 0.8928571428571429em;
        }
        &--no-mb {
            margin-bottom: 0;
        }
        &--s {
            font-size: 18px;
        }
        &--success {
            color: $color-secondary;
        }
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        &--bordered {
            border: 2px solid $color-happy;
            padding: 2.058823529411765em 2.647058823529412em;
            @media (max-width: $phone) {
                padding: 1.5em;
            }
        }
        &--padded-left {
            padding-left: 2em;
            @media (max-width: $phablet) {
                padding-left: 0;
            }
        }
        &__item {
            flex-basis: 100%;
            max-width: 100%;
            flex-grow: 1;
            flex-shrink: 1;
            padding-bottom: 0.375em;
            &-content {
                position: relative;
                font-size: 1.0625em;
                padding-left: 36px;
                line-height: 1.3;
                &:before {
                    content: "";
                    background-image: url(../images/icons/ring-bullet.svg);
                    background-repeat: no-repeat;
                    width: 18px;
                    height: 9px;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 0.41176470588235294117647058823529em;
                    flex-shrink: 0;
                    background-size: contain;
                    @media (max-width: $tablet) {
                        top: 6px;
                    }
                }
            }
            &:nth-last-child(2):nth-child(odd) {
                padding-bottom: 0;
            }
            &-link {
                color: $color-happy;
            }
            &-text {
                color: $color-primary;
            }
        }
        &--user-bullet &__item-content:before {
            background-image: url(../images/icons/user-bullet.svg);
            width: 18px;
            height: 17px;
            top: 0.17647058823529411764705882352941em;
            @media (max-width: $phablet) {
                top: 0.11764705882352941176470588235294em;
            }
        }
        &--bigger &__item-content {
            font-size: 1.25em;
            padding-left: 1.9em;
            &:before {
                width: 24px;
                height: 13px;
                top: 0.35em;
            }
        }
        &--two-cols &__item {
            @media (min-width: 769px) {
                flex-basis: 50%;
                max-width: 50%;
                &:nth-child(2n-1) {
                    padding-right: 1.176470588235294em;
                }
                &:nth-child(2n) {
                    padding-left: 1.176470588235294em;
                }
                &:nth-last-child(2) {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.padded-section {
    padding: 4em 0;
    @media (max-width: $tablet) {
        padding: 3em 0;
    }
    @media (max-width: $phone) {
        padding: 2em 0;
    }
}

.bordered-box {
    border: 1px solid $color-border;
    padding: 2.5em;
    &--no-border-b {
        border-bottom: none;
    }
    @media (max-width: $phone) {
        padding: 2em;
    }
    &__container {
        max-width: 870px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* video embed */
.video {
    &-container {
        width: 100%;
    }
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    margin: 0 auto;
    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
    }
}

.form-button {
    background-color: $color-danger;
    display: inline-block;
    color: $color-default;
    font-size: 1em;
    font-weight: 400;
    padding: 0.4375em 1.6875em;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    margin: 0;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        transform: translateX(-101%);
        background-color: $color-primary;
        transition: 0.3s ease;
    }
    .no-touch &:hover:before {
        transform: translateX(0);
    }
    &__text {
        color: $color-default;
        position: relative;
        z-index: 2;
    }
}
