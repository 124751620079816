.accordion {
	&__item {
		padding: 2em;
		border-bottom: 1px solid rgba($color-secondary, .4);
		position: relative;
		margin-top: -1px;
		@media (max-width: $phablet) {
			padding: 1.3em 2em;
			font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (640 - 320)));
		}
		&:before {
			content: "";
			position: absolute;
			top: -1px;
			bottom: -1px;
			left: 0;
			right: 0;
			border: 2px solid transparent;
			transition: .3s ease;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			transition: .3s ease;
		}
		&:last-child:not(:first-child) {
			border-bottom: none;
		}
		&:first-child {
			margin-top: 0;
		}
		&.active {
			padding-right: 2em;
			&:before {
				opacity: 1;
				visibility: visible;
				border-color: $color-secondary;
			}
		}
		&-container {
			transform: translateX(-2.0625em);
			transition: .3s ease;
			position: relative;
			z-index: 2;
		}
		&.active &-container {
			transform: translateX(0);
		}
	}
	&__main-button {
		display: flex;
		padding-left: 2.625em;
		position: relative;
		&-icon {
			flex-shrink: 0;
			width: 21px;
			height: 21px;
			position: absolute;
			left: 0;
			top: 0.125em;
			display: inline-block;
			color: $color-secondary;
			transition: .4s ease;
			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background-color: currentColor;
			}
			&:before {
				content: "";
				width: 3px;
				height: 100%;
				transition: .4s ease;
			}
			&:after {
				content: "";
				width: 100%;
				height: 3px;
			}
		}
		&-text {
			color: $color-dark;
			font-size: 1.375em;
			font-weight: 400;
			display: inline-block;
			text-align: left;
		}
	}
	&__item.active &__main-button {
		&-icon {
			transform: rotate(180deg);
			&:before {
				opacity: 0;
			}
		}
	}
	&__expand-button {
		display: none;
		&-text {
			display: inline-block;
			&--hide {
				display: none;
			}
		}
	}
	&__content {
		display: none;
	}
	&__padded-field {
		padding: 1em 0 0 2.625em;
		@media (max-width: $phablet) {
			padding-left: 0;
		}
	}
	&__text {
		color: $color-text;
		font-size: 17px;
		font-weight: 400;
		line-height: 1.5;
		max-height: 6em;
		overflow: hidden;
	}
	&__item.show-expand-button & {
		&__text {
			margin-bottom: 1.7058823529411764705882352941176em;
		}
		&__expand-button {
			display: inline-flex;
		}
	}
	&__item.expanded-content &__expand-button-text {
		&--expand {
			display: none;
		}
		&--hide {
			display: inline-block;
		}
	}
	&__container {
		// display: flex;
		// align-items: center;
		padding-left: 10vw;
		position: relative;
		@media (max-width: $tablet) {
			padding-left: 0;
		}
		&-items {
			width: 100%;
		}
	}
	&__decoration {
		position: absolute;
		top: 50%;
		right: 100%;
		transform: translateY(-50%);
		margin-right: -7vw;
		@media (max-width: $tablet) {
			display: none;
		}
	}
}
