.main-footer {
    font-size: 1rem;
    &__top {
        margin-bottom: -2em;
        position: relative;
        z-index: 4;
        @media(max-width: $laptop) {
            font-size: .85rem;
        }
        @media(max-width: $tablet) {
            font-size: .7rem;
        }
        @media(max-width: $phablet) {
            background-color: $color-tertiary;
            padding-top: 4em;
        }
        @media(max-width: $phone) {
            padding-top: 3em;
        }
        @media(max-width: $phone) {
            font-size: .65rem;
        }
    }
    &__middle {
        background-color: $color-tertiary;
        color: $color-default;
        padding: 7.875em 0 5.375em;
        position: relative;
        @media(max-width: $tablet) {
            padding: 5em 0 3em;
        }
        @media(max-width: $phablet) {
            padding-top: 4em;
        }
        @media(max-width: $phone) {
            padding-bottom: 2em;
        }
        &-content {
            display: flex;
            align-items: flex-start;
            position: relative;
            z-index: 4;
            @media(max-width: $phablet) {
                flex-wrap: wrap;
            }
        }
    }
    &__bottom {
        background-color: $color-primary;
        padding: 1.625em 0 1.75em 0;
        &-content {
            display: flex;
            align-items: center;
            padding-right: 12.625em;
            @media(max-width: $laptop) {
                flex-wrap: wrap;
                padding-right: 0;
            }
        }
    }
    &__copyright {
        color: rgba($color-default, .8);
        font-size: 0.875em;
        font-weight: 400;
        display: inline-block;
        line-height: 1.64;
        max-width: 14.428571428571428571428571428571em;
        margin-right: 1.5em;
        @media(max-width: $laptop) {
            margin-right: 0;
            order: 1;
            max-width: 100%;
            display: block;
            width: 100%;
            text-align: center;
        }
    }
}

.footer {
    &-main-nav {
        max-width: 30em;
        flex-basis: 30em;
        flex-grow: 0;
        flex-shrink: 1;
        margin-right: 3em;
        @media(max-width: $tablet) {
            margin-right: 2.5em;
        }
        @media(max-width: $phablet) {
            max-width: 100%;
            flex-basis: 100%;
            flex-grow: 1;
            margin: 0 0 3em 0;
        }
        &__link {
            color: $color-default;
            font-size: 1.125em;
            font-weight: 400;
            line-height: 1.3;
        }
    }
    &-additional-nav {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        color: $color-default;
        @media(max-width: $laptop) {
            flex-basis: 100%;
            max-width: 100%;
            justify-content: center;
            padding-bottom: 1.25em;
            flex-wrap: wrap;
        }
        &__item {
            position: relative;
            display: flex;
            align-items: center;
            padding-right: 1.3125em;
            @media(max-width: $phablet) {
                flex-basis: 100%;
                padding-right: 0;
                padding-bottom: 0.75em;
                margin-bottom: 0.75em;
                justify-content: center;
            }
            &:after {
                content: "";
                width: 1px;
                height: 1.5em;
                background-color: $color-text;
                margin-left: 2em;
                @media(max-width: $phablet) {
                    width: 1.5em;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-left: 0;
                }
            }
            &:last-child {
                padding: 0;
                margin: 0;
                &:after {
                    display: none;
                }
            }
            &-link {
                font-size: 0.9375em;
                font-weight: 400;
            }
        }
    }
    &-contacts {
        flex-shrink: 0;
        @media(max-width: $phablet) {
            flex-basis: 100%;
            max-width: 100%;
            flex-grow: 1;
            flex-shrink: 1;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
        &__item {
            padding-bottom: 0.8125em;
            @media(max-width: $phablet) {
                flex-basis: 100%;
                max-width: 100%;
                display: flex;
                justify-content: center;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        &__address {
            font-size: 0.9375em;
        }
        &__social {
            display: flex;
            align-items: center;
            &-item {
                padding-right: 1.5625em;
                &:last-child {
                    padding-right: 0;
                }
            }
            &-link {
                color: $color-default;
                & .icon {
                    fill: currentColor;
                }
                &--fb .icon {
                    width: 0.625em;
                    height: 1.25em;
                }
                &--tw .icon {
                    width: 1.5625em;
                    height: 1.625em;
                }
            }
        }
    }
    &-decoration {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 429px;
        overflow: hidden;
        @media(max-width: $laptop) {
            display: none;
        }
        &__img {
            position: absolute;
            &--oval {
                top: 70px;
                right: 130px;
                width: 78px;
                height: 78px;
                z-index: 2;
            }
            &--hand {
                bottom: -20px;
                right: 0;
                z-index: 3;
            }
            &--fingers {
                bottom: 113px;
                right: 25px;
                z-index: 1;
                width: 185px;
                height: 106px;
            }
        }
    }
    &-button {
        &__link {
            display: flex;
            &-icon {
                background-color: $color-primary;
                width: 4.25em;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                & img {
                    width: 29px;
                    height: 55px;
                    @media(max-width: $laptop) {
                        width: 25px;
                        height: 47px;
                    }
                    @media(max-width: $tablet) {
                        width: 22px;
                        height: 42px;
                    }
                }
            }
            &-content {
                position: relative;
                padding: 2.0625em 2em 2.125em 4.1875em;
                width: 100%;
                overflow: hidden;
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-image: url(../images/icons/bookright.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 29px;
                    height: 55px;
                    z-index: 2;
                    @media(max-width: $laptop) {
                        width: 25px;
                        height: 47px;
                    }
                    @media(max-width: $tablet) {
                        width: 22px;
                        height: 42px;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    transform: translateX(-100%);
                    background-color: $color-primary;
                    z-index: 1;
                    transition: .3s ease;
                }
            }
            &--type-1 &-content {
                background-color: $color-quaternary;
            }
            &--type-2 &-content {
                background-color: $color-happy;
            }
            &-text {
                color: $color-default;
                font-size: 1.5em;
                font-weight: 400;
                display: inline-block;
                position: relative;
                z-index: 2;
            }
            .no-touch &:hover &-content:after {
                transform: translateX(0);
            }
        }
    }
}