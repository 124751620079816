.page-controller {
    display: flex;
    align-items: center;
    @media(max-width: $tablet) {
        & .dropdown-container {
            position: static;
            &.active .dropdown-content {
                transform: translateX(0) translate(0);
            }
        }
        & .dropdown-content {
            transform: translateX(0) translateY(10px);
            margin-top: 0;
            left: 0;
            right: 0;
        }
    }
    &-container {
        padding-top: 1em;
        &--border-top {
            border-top: 0.1875em solid $color-text;
            padding-top: 2em;
        }
        &__content {
            padding-bottom: 1em;
            border-bottom: 1px solid $color-border;
            position: relative;
        }
    }
    &__item {
        margin-right: 1.875em;
        &:last-child {
            margin-right: 0;
        }
    }
    &__content {
        display: flex;
        align-items: center;
        color: $color-text;
        transition: .3s ease;
    }
    &__text {
        font-size: 0.8125em;
        font-weight: 400;
        display: inline-block;
        margin-left: 0.76923076923076923076923076923077em;
        @media(max-width: $tablet) {
            display: none;
        }
    }
    &__logo {
        height: 23px;
        display: flex;
        align-items: center;
        & .icon {
            flex-shrink: 0;
            fill: $color-primary;
        }
        &--share {
            & .icon {
                width: 20px;
                height: 23px;
            }
        }
        &--mail {
            & .icon {
                width: 31px;
                height: 17px;
            }
        }
        &--print {
            & .icon {
                width: 24px;
                height: 21px;
            }
        }
    }
}

.no-touch button.page-controller__content:hover {
    color: $color-happy;
    & .icon {
        fill: currentColor;
    }
}

.font-size-controller {
    display: flex;
    align-items: center;
    &__button {
        width: 0.875em;
        height: 0.875em;
        position: relative;
        color: $color-happy;
        transition: .3s ease;
        &:disabled {
            opacity: .4;
            cursor: not-allowed;
        }
        &:before,
        &:after {
            content: "";
            background-color: currentColor;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
        &:before {
            width: 100%;
            height: 2px;
        }
        &:after {
            width: 2px;
            height: 100%;
        }
        &--decrease:after {
            display: none;
        }
        .no-touch &:hover:not(:disabled) {
            color: $color-primary;
        }
    }
    &__logo {
        color: $color-primary;
        font-size: 1.8125em;
        font-weight: 300;
        line-height: 1.02;
        margin-left: 4px;
        margin-right: 4px;
    }
}

.breadcrumbs {
    &__item {
        display: flex;
        align-items: center;
        font-size: 0.8125em;
        color: $color-primary;
        height: 100%;
        &:after {
            content: ">";
            margin: 0 0.23076923076923076923076923076923em;
        }
    }
    & .grid__item:last-child &__item:after {
        display: none;
    }
    &__link {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        max-width: 18.461538461538461538461538461538em;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &-icon {
            margin-right: 0.69230769230769230769230769230769em;
            & .icon {
                width: 11px;
                height: 13px;
                fill: currentColor;
                display: inline-block;
                margin-top: -0.15384615384615384615384615384615em;
            }
        }
    }
}