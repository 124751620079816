.site-message {
    background-color: $color-dropdown;
    &__content {
        padding: 0.5em 0.875em;
        display: flex;
        align-items: center;
    }
    &__icon {
        flex-shrink: 0;
        padding-right: 0.4375em;
        & .icon {
            width: 28px;
            height: 23px;
        }
    }
    &__text {
        color: $color-text;
        font-size: 1rem;
        font-weight: 400;
    }
}

.cookies {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.9375em 1.125em;
    @media(max-width: $phablet) {
        flex-wrap: wrap;
        justify-content: center;
    }
    &__icon {
        flex-shrink: 0;
        padding-right: 0.6875em;
        & .icon {
            width: 32px;
            height: 33px;
        }
    }
    &__content {
        color: $color-text;
        display: flex;
        padding-right: 1em;
        @media(max-width: $phablet) {
            padding: 0 0 1em;
        }
    }
    &__text {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
    }
    &__heading {
        font-size: 1.125em;
        font-weight: 400;
        display: inline-block;
        padding-right: 0.72222222222222222222222222222222em;
    }
    &__paragraph {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        padding-right: 1em;
    }
    &__link {
        font-size: 0.875em;
        display: inline-block;
    }
    &__button {
        background-color: $color-danger;
        display: inline-block;
        color: $color-default;
        font-size: 1em;
        font-weight: 400;
        padding: 0.4375em 1.6875em;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            transform: translateX(-101%);
            background-color: $color-primary;
            transition: .3s ease;
        }
        .no-touch &:hover:before {
            transform: translateX(0);
        }
        &-text {
            position: relative;
            z-index: 2;
        }

    }
}