.article {
	font-size: 1em;
	@media (max-width: $tablet) {
		font-size: 0.9375em;
	}
	@media (max-width: $tablet) {
		font-size: 0.875em;
	}
	&--inline {
		@media (min-width: 641px) {
			display: flex;
			align-items: flex-start;
		}
	}
	&__item {
		padding-bottom: 0.625em;
		flex-basis: 100%;
		max-width: 100%;
		flex-grow: 1;
		flex-shrink: 1;
		&--custom {
			padding-bottom: 0.8125em;
			flex-basis: 12.5em;
			max-width: 12.5em;
			flex-grow: 0;
			flex-shrink: 0;
			margin-right: 2.9375em;
			border-bottom: 3px solid $color-secondary;
			@media (max-width: $phablet) {
				margin-right: 0;
				margin-bottom: 1em;
			}
		}
		&:last-child {
			padding-bottom: 0;
		}
		& .text {
			padding-bottom: 1.0625em;
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
	&__time {
		display: flex;
		align-items: flex-end;
		&-day {
			color: $color-default;
			font-size: 38px;
			line-height: 1;
			font-weight: 400;
			width: 48px;
			height: 53px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-image: url(../images/icons/calendar.svg);
			background-repeat: no-repeat;
			background-size: contain;
			margin-right: 0.31578947368421052631578947368421em;
			padding-top: 0.18421052631578947368421052631579em;
		}
		&-date {
			color: $color-dark;
			font-size: 1.25em;
			font-weight: 400;
			padding-bottom: 0.45em;
		}
	}
	&__title {
		color: $color-primary;
		font-size: 1.3125em;
		font-weight: 400;
		line-height: 1.4;
		margin-bottom: 0.66666666666666666666666666666667em;
		&--bigger {
			font-size: 2.25em;
			margin-bottom: 1.4444444444444444444444444444444em;
			color: $color-text;
			@media (max-width: $phablet) {
				font-size: 1.6em;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__text {
		color: $color-text;
		font-size: 1em;
		font-weight: 400;
		line-height: 1.5;
		padding-bottom: 1.5em;
		&--bigger {
			font-size: 1.0625em;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
	&--inline & {
		&__text {
			max-height: 3em;
			overflow: hidden;
		}
	}
	&__container {
		@media (min-width: 641px) {
			display: flex;
			align-items: flex-start;
		}
	}
}
