.top-header {
    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 15em;
        @media(max-width: $phone) {
            min-height: 12em;
        }
        &:after {
            content: "";
            font-size: 0;
            min-height: inherit;
        }
    }
    &__content {
        padding-bottom: 1.25em;
        width: 100%;
    }
    &__title {
        color: $color-default;
        font-size: 2.6875em;
        font-weight: 400;
        line-height: 1.2;
        @media(max-width: $phone) {
            font-size: 2.2em;
        }
    }
    & .breadcrumbs {
        @media(max-width: $laptop) {
            display: none;
        }
        &__item {
            color: $color-default;

        }
    }
}