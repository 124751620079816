.site-form {
    font-size: 1em;
    &__label {
        font-size: 1em;
        font-weight: 400;
        display: block;
        margin-bottom: 10px;
        color: $color-dark;
    }
    &__select,
    &__input,
    &__textarea {
        padding: 11px;
        font-size: 1em;
        border: 1px solid #979797;
        background-color: $color-default;
        color: $color-primary;
        width: 100%;
        transition: 0.3s ease;
        -webkit-appearance: none;
        border-radius: 0;
        height: 100%;
        line-height: 1;
        font-weight: 400;
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-size: 1em;
            color: $color-primary;
            line-height: normal !important;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            font-size: 1em;
            color: $color-primary;
            line-height: normal !important;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            font-size: 1em;
            color: $color-primary;
            line-height: normal !important;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            font-size: 1em;
            color: $color-primary;
            line-height: normal !important;
        }
        .error & {
            border-color: $color-danger;
        }
    }
    &__select {
        padding: 13px 26px 13px 13px;
        background-image: url("../images/triangle.svg");
        background-repeat: no-repeat;
        background-position: center right 11px;
        background-size: 10px 6px;
        line-height: 1.2;
        border: 2px solid $color-primary;
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &::-ms-expand {
            display: none;
        }
        & option:disabled {
            background-color: transparent !important;
        }
    }
    &__textarea {
        resize: vertical;
    }
    &__select:focus,
    &__input:focus,
    &__textarea:focus {
        outline: none;
        border-color: $color-primary;
    }
    &__input {
        &--no-border {
            border-color: transparent !important;
        }
    }
}

.error {
    &__message {
        display: block;
        width: 100%;
        font-size: 0.875em;
        padding-top: 0.35714285714285714285714285714286em;
        color: $color-danger;
    }
}

/* custom checkbox */
.custom-checkbox {
    display: inline-block;
    cursor: pointer;
    &__text {
        display: inline-block;
        position: relative;
        padding-left: 38px;
        font-size: 17px;
        &:before,
        &:after {
            content: "";
            position: absolute;
            transition: 0.3s ease;
            display: inline-block;
        }
        &:before {
            top: 1px;
            left: 0;
            width: 22px;
            height: 22px;
            border: 1px solid $color-danger;
            background-color: $color-default;
        }
        &:after {
            width: 12px;
            height: 6px;
            border-bottom: 3px solid $color-default;
            border-left: 3px solid $color-default;
            top: 7px;
            left: 5px;
            transform: rotate(-45deg);
            opacity: 0;
        }
    }
    &__input {
        display: none;
    }

    &__input:checked ~ &__text:after {
        opacity: 1;
    }
    &__input:checked ~ &__text:before {
        background-color: $color-danger;
    }
}
/* end of custom checkbox */

/* custom radio */
.custom-radio {
    display: inline-block;
    cursor: pointer;
    &__text {
        display: inline-block;
        position: relative;
        padding-left: 38px;
        font-size: 17px;
        &:before,
        &:after {
            content: "";
            border-radius: 50%;
            position: absolute;
            transition: 0.3s ease;
        }
        &:before {
            width: 27px;
            height: 27px;
            top: 0;
            border-color: #7f7f7f;
        }
        &:after {
            width: 11px;
            height: 11px;
            display: inline-block;
            background-color: $color-danger;
            top: 8px;
            left: 8px;
            opacity: 0;
        }
    }
    &__input {
        display: none;
    }
    .lt-ie9 &__input {
        display: inline-block;
        vertical-align: middle;
    }

    .lt-ie9 &__text:before,
    .lt-ie9 &__text:after {
        display: none;
    }

    &__input:checked ~ &__text:after {
        opacity: 1;
    }
}
/* end of custom radio */

/* form styles */
.form-group {
    margin-bottom: 24px;
    label {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 14px;
            height: 14px;
            right: 0;
            left: 100%;
            bottom: 50%;
            top: 50%;
            transform: translate(0, -50%);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 10px;
        }
    }
    .is-invalid {
        // color: $color-danger;
        & ~ label {
            // color: $color-danger;
            &::after {
                background-image: url(../images/ex-mark.svg);
            }
        }
    }
    .is-valid {
        // color: $color-success;
        & ~ label {
            // color: $color-success;
            &::after {
                background-image: url(../images/check-mark.svg);
            }
        }
    }
    .text .is-valid {
        color: $color-success;
    }
}
/* end of form styles */
