.lined-list {
	@media(max-width: $phone) {
		font-size: 14px;
	}
	&__item {
		padding-bottom: 2em;
		&:last-child {
			padding-bottom: 0;
		}
	}
	&__heading {
		color: $color-primary;
		font-size: 1.3125em;
		font-weight: 400;
		margin-bottom: 0.80952380952380952380952380952381em;
		display: block;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__content {
		color: $color-text;
		font-size: 1.0625em;
		font-weight: 400;
		line-height: 1.5;
		padding-left: 3.6470588235294117647058823529412em;
		position: relative;
		@media(max-width: $phone) {
			padding-left: 2.5em;
		}
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0.70588235294117647058823529411765em;
			background-color: $color-secondary;
			width: 50px;
			height: 3px;
			display: inline-block;
			@media(max-width: $phone) {
				width: 28px;
				top: 9px;
				height: 2px;
			}
		}
		& a {
			color: $color-happy;
		}
		& p {
			padding-bottom: 1.25em;
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}