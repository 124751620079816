.features-list {
    font-size: 1rem;
    @media(max-width: $phone) {
        font-size: .9rem;
    }
    &__item {
        padding: 2.0625em 2.1875em;
        height: 100%;
        text-align: center;
        display: block;
    }
    & .grid__item:nth-child(3n-2) &__item {
        background-color: $color-tertiary;
    }
    & .grid__item:nth-child(3n-1) &__item {
        background-color: $color-quaternary;
    }
    & .grid__item:nth-child(3n) &__item {
        background-color: $color-happy;
    }
    &__icon {
        display: inline-block;
        width: 6.5em;
        height: 7.5em;
        position: relative;
        background-image: url(../images/icons/hexagon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 1.4375em;
        & .icon {
            position: absolute;
            transition: .3s ease;
            &--wave {
                width: 3.6875em;
                height: 3.125em;
                top: 1.625em;
                z-index: 1;
            }
            &--padlock {
                fill: $color-default;
                width: 2.3125em;
                height: 3.8125em;
            }
            &--pencil {
                fill: $color-default;
                width: 3em;
                height: 3.0625em;
            }
            &--hand {
                width: 4.75em;
                height: 3.3125em;
                z-index: 2;
                top: 2.8125em;
                right: -0.125em;
                fill: $color-default;
                &-reversed {
                    z-index: 1;
                    top: 1.0625em;
                    left: -0.625em;
                    fill: $color-happy;

                }
            }
            &--center {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 2;
            }
        }
        &--padlock .icon--wave {
            fill: $color-happy;
            left: -1.5625em;
        }
        &--pencil .icon--wave {
            fill: $color-quaternary;
            left: -1.25em;
        }
    }
    .no-touch &__item:hover &__icon {
        &--hands {
            & .icon {
                &--hand {
                    transform: translateX(20px);
                }
                &--hand-reversed {
                    transform: translateX(-20px);
                }
            }
        }
        & .icon {
            &--wave {
                // left: 50%;
                transform: translateX(-20px);
            }
            &--padlock,
            &--pencil {
                transform: translateX(10px)
            }
        }
    }
    &__title {
        display: block;
        color: $color-default;
        font-size: 1.25em;
        font-weight: 700;
        line-height: 1.3;
        text-transform: uppercase;
        margin-bottom: 1.125em;
        text-align: left;
    }
    &__text {
        color: rgba($color-default, .75);
        font-size: 1em;
        font-weight: 400;
        line-height: 1.5;
        max-height: 6em;
        overflow: hidden;
        text-align: left;
    }
}