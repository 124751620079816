.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: none;

    &.active {
        display: flex;
    }

    &__item {
        border: 8px solid $color-default;
        border-top: 8px solid $color-primary;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        display: inline-block;
        animation: spin .8s linear infinite;
    }

}

@keyframes spin {
    0% { transform: rotate(0deg); transform: rotate(0deg); }
    100% { transform: rotate(360deg); transform: rotate(360deg); }
}