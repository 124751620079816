.main-header {
	position: relative;
	z-index: 100;
	&__container {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		@media (max-width: $tablet) {
			align-items: center;
		}
	}
	&__content {
		&-item {
			display: flex;
			padding-bottom: 1.4375em;
			justify-content: flex-end;
			&:last-child {
				padding-bottom: 0;
			}
		}
		&--type-2 {
			& .header-main-nav__container {
				position: absolute;
				top: 100%;
				background-color: $color-default;
				width: 190px;
				right: 50%;
				transform: translateX(50%) translateY(10px);
				border: 1px solid $color-border;
				border-bottom: none;
				opacity: 0;
				visibility: hidden;
				transition: .3s ease;
				display: block;
				margin-top: 4px;
				@media (max-width: $tablet) {
					right: -6vw !important;
					left: auto;
					transform: translateX(0%) translateY(10px);
					margin-top: 21px;
				}
				@media (max-width: $phone) {
					right: -7vw !important;
					margin-top: 19px;
				}
			}
			& .menu-opened .header-main-nav__container {
				transform: translateX(50%) translateY(0);
				opacity: 1;
				visibility: visible;
				@media (max-width: $tablet) {
					transform: translateX(0%) translateY(0);
				}
			}
			& .header-main-nav {
				&__button {
					width: 100%;
					justify-content: center;
					&:before {
						display: none;
					}
				}
				&__dropdown {
					position: static;
					opacity: 1;
					visibility: visible;
					transform: none !important;
					padding: 0;
					transition: 0s;
					background-color: $color-default;
					display: none;
					&-title {
						display: none;
					}
					&-content,
					&-container {
						display: block;
					}
				}
			}
			.header-dropdown-list {
				margin: 0;
				&__item {
					flex-basis: 100%;
					flex-grow: 1;
					padding: 0;
					&-link {
						padding: 0.625em;
						display: block;
						width: 100%;
						text-align: center;
						font-size: 0.875em;
						padding-left: 0;
						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
}

.header {
	&-additional-nav {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		&__item {
			padding-right: 1.875em;
			@media (max-width: $phone) {
				padding-right: 1em;
			}
			&:last-child {
				padding-right: 0;
			}
			&-button {
				color: $color-primary;
				font-size: 1em;
				font-weight: 400;
				text-transform: uppercase;
				display: inline-block;
				&-text {
					transition: .3s ease;
				}
				.no-touch &:hover &-text,
				.no-touch &:hover .icon {
					opacity: .7;
				}
				&--with-icon .icon {
					width: 1.125em;
					height: 1.125em;
					transition: .3s ease;
				}
			}
		}
	}
	&-main-nav {
		display: flex;
		align-items: center;
		height: 50px;
		@media (max-width: $desktop) {
			position: relative;
		}
		@media (max-width: $tablet) {
			height: auto;
		}
		.main-header__content--type-2 & {
			position: relative;
		}
		&__item {
			padding-right: 0.375em;
			@media (max-width: $desktop) {
				padding-right: 0;
			}
			.main-header__content--type-2 & {
				padding-right: 0;
				border-bottom: 1px solid $color-border;
				&:last-child {
					border-bottom: none;
				}
			}
			&:last-child {
				padding-right: 0;
			}
			@media (max-width: $desktop) {
				border-bottom: 1px solid $color-border;
				&:last-child {
					border-bottom: none;
				}
			}
			&-group {
				.main-header__content--type-2 & {
					display: flex;
				}
				@media (max-width: $desktop) {
					display: flex;
				}
			}
			& .header-special-nav {
				padding-left: 0;
				@media (max-width: $tablet) {
					display: block !important;
				}
				&__button {
					background-color: #2e3092;
					@media (max-width: $desktop) {
						width: 100%;
						text-align: center;
					}
					&:before {
						background-color: #0094c8;
					}
					&--default {
						background-color: $color-danger;
						&:before {
							background-color: $color-primary;
						}
					}
				}
			}
		}
		&__item.active & {
			&__button {
				text-decoration: none;
				background-color: $color-dropdown;
				&--dropdown {
					text-decoration: none;
				}
				@media (max-width: $desktop) {
					text-decoration: underline;
					background-color: transparent;
				}
				.main-header__content--type-2 & {
					background-color: transparent;
					text-decoration: underline;
				}
				&:before {
					opacity: 1;
					transform: none;
					visibility: visible;
					border-color: transparent;
					@media (max-width: $desktop) {
						display: none;
					}
				}
			}
		}
		&__container {
			display: flex;
			@media (max-width: $desktop) {
				position: absolute;
				top: 100%;
				background-color: $color-default;
				width: 190px;
				right: 50%;
				transform: translateX(50%) translateY(10px);
				border: 1px solid $color-border;
				margin-top: 4px;
				opacity: 0;
				visibility: hidden;
				transition: .3s ease;
				display: block;
				.menu-opened & {
					transform: translateX(50%) translateY(0);
					opacity: 1;
					visibility: visible;
				}
				.main-header__content--type-3 & {
					right: -4vw;
					transform: translateX(0) translateY(10px);
					@media (max-width: $laptop) {
						right: -5vw;
					}
				}
				.main-header__content--type-3 .menu-opened & {
					transform: translateX(0) translateY(0);
				}
			}
			@media (max-width: $tablet) {
				right: -6vw !important;
				left: auto;
				transform: translateX(0%) translateY(10px);
				margin-top: 21px;
				.menu-opened & {
					transform: translateX(0%) translateY(0);
				}
			}
			@media (max-width: $phone) {
				right: -7vw !important;
				margin-top: 19px;
			}
		}
		&__button {
			color: $color-primary;
			font-size: 1rem;
			font-weight: 400;
			display: flex;
			height: 100%;
			align-items: center;
			padding: 1em 0.6875em;
			@media (max-width: $desktop) {
				width: 100%;
				justify-content: center;
			}
			&--custom {
				color: $color-happy;
				padding-right: 0;
			}
			&-text {
				display: inline-block;
				position: relative;
				z-index: 2;
			}
			&--dropdown {
				position: relative;
				overflow: hidden;
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: -1px;
					background-color: $color-dropdown;
					border: 1px solid $color-dropdown-border;
					border-bottom-color: $color-dropdown;
					z-index: 2;
					transform: translateY(100%);
					transition: .3s;
					opacity: 0;
					visibility: hidden;
				}
			}
		}
		&__item-group.active &__button {
			@media (max-width: $desktop) {
				text-decoration: underline;
			}
			&:before {
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
				border-color: transparent;
				background-color: $color-light;
				@media (max-width: $desktop) {
					display: none;
				}
			}
		}
		.no-touch &__item:hover & {
			&__button {
				overflow: visible;
			}
			&__button:before {
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
				background-color: $color-dropdown;
				border-color: $color-dropdown-border;
				border-bottom-color: $color-dropdown;
				transition-delay: .2s;
				@media (max-width: $desktop) {
					display: none;
				}
			}
			&__dropdown {
				transform: scale(1, 1);
				opacity: 1;
				visibility: visible;
			}
		}
		&__dropdown {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background-color: $color-dropdown;
			border-top: 1px solid $color-dropdown-border;
			padding: 4.6875em 0;
			opacity: 0;
			visibility: hidden;
			transform: scale(1, 0);
			transform-origin: top;
			transition: .3s ease;
			z-index: 1;
			@media (max-width: $desktop) {
				display: none;
				position: static;
				opacity: 1;
				visibility: visible;
				transform: none;
				padding: 0;
				transition: 0s;
				background-color: $color-default;
				& .wrapper {
					width: 100%;
				}
			}
			&-container {
				display: flex;
				align-items: center;
				width: 100%;
				@media (max-width: $desktop) {
					display: block;
				}
			}
			&-title {
				color: $color-text;
				font-size: 2em;
				font-weight: 400;
				line-height: 1.25;
				margin-right: 1em;
				flex-shrink: 0;
				@media (max-width: $desktop) {
					display: none;
				}
			}
			&-content {
				display: flex;
				margin-left: auto;
				margin-right: auto;
				flex-wrap: wrap;
				@media (max-width: $desktop) {
					display: block;
				}
			}
			&-item {
				flex-basis: auto;
				flex-grow: 0;
				flex-shrink: 1;
				max-width: 100%;
				padding: 1.125em;
			}
			&-button {
				flex-basis: 30px;
				max-width: 30px;
				flex-grow: 0;
				flex-shrink: 0;
				border-left: 1px solid $color-border;
				align-items: center;
				justify-content: center;
				display: none;
				@media (max-width: $desktop) {
					display: flex;
				}
				.main-header__content--type-2 & {
					display: flex;
				}
				&:before {
					content: "";
					width: 8px;
					height: 8px;
					border-right: 1px solid currentColor;
					border-bottom: 1px solid currentColor;
					transform-origin: center;
					transform: rotate(45deg);
					transition: transform .3s ease;
					margin-top: -2px;
					display: inline-block;
					.dd-opened & {
						transform: rotate(225deg);
						margin-top: 2px;
					}
				}
			}
		}
		&__group--dropdowns &__group-item.opened &__button-text:after {
			transform: rotate(-135deg);
			margin-top: 0;
		}
	}
	&-special-nav {
		display: flex;
		flex-direction: row-reverse;
		padding-left: 1.125rem;
		font-size: 0;

		.main-header__content--type-2 & {
			padding-left: 2.125rem;
			@media (max-width: $desktop) {
				padding-left: 1.125rem;
			}
		}
		@media (max-width: $tablet) {
			display: none;
		}
		&__item {
			padding-left: 0.625rem;
			&:last-child {
				padding-left: 0;
			}
		}
		&__button {
			background-color: $color-danger;
			display: inline-block;
			color: $color-default;
			font-size: 1rem;
			font-weight: 700;
			padding: 1rem;
			position: relative;
			overflow: hidden;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				transform: translateX(-101%);
				background-color: $color-primary;
				transition: .3s ease;
			}
			.no-touch &:hover:before {
				transform: translateX(0);
			}
			&-text {
				position: relative;
				z-index: 2;
			}
		}
	}
	&-dropdown-list {
		margin: -1.125em;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		@media (max-width: $desktop) {
			margin: 0;
		}
		&__item {
			padding: 1.125em;
			flex-basis: auto;
			flex-grow: 0;
			flex-shrink: 1;
			display: block;
			@media (max-width: $desktop) {
				flex-basis: 100%;
				flex-grow: 1;
				padding: 0;
			}
			&-link {
				display: inline-block;
				color: $color-primary;
				font-family: "Roboto Condensed";
				font-size: 1.125rem;
				font-weight: 400;
				padding-left: 0.88888888888888888888888888888889em;
				position: relative;
				&.active {
					text-decoration: underline;
				}
				@media (max-width: $desktop) {
					display: block;
					width: 100%;
					text-align: center;
					font-size: 0.875em;
					padding: 0.625em;
				}
				&:before {
					content: "";
					display: inline-block;
					width: 0.27777777777777777777777777777778em;
					height: 0.27777777777777777777777777777778em;
					background-color: $color-happy;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: 0.38888888888888888888888888888889em;
					@media (max-width: $desktop) {
						display: none;
					}
				}
			}
		}
	}
}

.site-logo {
	display: inline-block;
	font-size: 1em;
	margin: 0.75em 0 0.625em;
	max-width: 11.5625em;
	width: 100%;
	&__link {
		display: block;
		&-img {
			width: 100%;
		}
	}
}
.sandwich-button {
	position: relative;
	flex-shrink: 0;
	display: inline-block;
	margin-right: 0.875em;
	display: none;
	&:after {
		content: "";
		position: absolute;
		width: 1em;
		height: 1em;
		background-color: $color-default;
		border-top: 1px solid $color-border;
		border-left: 1px solid $color-border;
		left: 50%;
		top: 100%;
		margin-top: 13px;
		transform: translateX(-50%) translateY(10px) rotate(45deg);
		transition: .3s ease;
		opacity: 0;
		visibility: hidden;
		display: inline-block;
		@media (max-width: $phone) {
			margin-top: 11px;
			z-index: -1;
		}
	}
	&:last-child {
		margin-right: 0;
	}
	@media (max-width: $desktop) {
		display: inline-block;
	}
	.main-header__content--type-2 & {
		display: inline-block;
		margin-left: 0.9375em;
	}
	@media (max-width: $tablet) {
		margin-right: 0;
		margin-left: 0;
	}
	&__lines {
		position: relative;
		width: 1.8125em;
		height: 1em;
		display: flex;
		flex-direction: column;
		z-index: 10;
	}
	&__line {
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		top: 0;
		background-color: $color-primary;
		transition: .3s ease;

		&:nth-child(2) {
			position: static;
			margin-top: auto;
			margin-bottom: auto;
			transform: translate(0);
		}
		&:last-child {
			top: 100%;
			transform: translate(0, -100%);
		}
	}
	.menu-opened &__line {
		top: 50%;
		transform: translate(-50%, -50%);
		transform-origin: center center;

		&:nth-child(2n) {
			opacity: 0;
			transform: translate(0);
			visibility: hidden;
		}
		&:first-child {
			transform: translate(0, -50%) rotate(45deg);
		}
		&:last-child {
			transform: translate(0, -50%) rotate(-45deg);
		}
	}
	.menu-opened &:after {
		opacity: 1;
		visibility: visible;
		transform: translateX(-50%) translateY(0) rotate(45deg);
	}
}

.main-header__content:not(.main-header__content--type-2) .header-main-nav__dropdown {
	@media (min-width: 1201px) {
		display: block !important;
	}
}

@supports (-ms-ime-align: auto) {
	.main-header__content--type-2
		.header-main-nav__group--dropdowns
		.header-main-nav__button
		.header-main-nav__button-text {
		text-decoration: none;
	}
	.no-touch
		.main-header__content--type-2
		.header-main-nav__group--dropdowns
		.header-main-nav__button:hover
		.header-main-nav__button-text {
		text-decoration: underline;
	}
}
@media all and (-ms-high-contrast: none) {
	.main-header__content--type-2
		.header-main-nav__group--dropdowns
		.header-main-nav__button
		.header-main-nav__button-text {
		text-decoration: none;
	}
	.no-touch
		.main-header__content--type-2
		.header-main-nav__group--dropdowns
		.header-main-nav__button:hover
		.header-main-nav__button-text {
		text-decoration: underline;
	}
}
