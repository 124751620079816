.accent {
	border: 3px solid $color-tertiary;
	background-color: #f7f7fa;
	padding: 2em 1.875em 2.125em;
	@media(max-width: $phone) {
		padding: 1.5em;
	}
	&__text {
		color: $color-text;
		font-size: 1.0625em;
		font-weight: 400;
		line-height: 1.5;
		margin-bottom: 1.4705882352941176470588235294118em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}