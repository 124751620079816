.link {
    display: inline-block;
    color: currentcolor;
    max-width: 100%;

    &--underline {
        text-decoration-line: underline;
        text-decoration-color: currentcolor;
        & * {
            text-decoration-line: underline;
            text-decoration-color: currentcolor;
        }
        .no-touch &:hover,
        .no-touch &:hover * {
            text-decoration-color: transparent;
        }

    }
    &--normal {
        text-decoration-line: underline;
        text-decoration-color: transparent;
        & * {
            text-decoration-line: underline;
            text-decoration-color: transparent;
        }
        .no-touch &:hover,
        .no-touch &:hover * {
            text-decoration-color: currentcolor;
        }
    }
    &--opacity {
        &:hover {
            opacity: .7;
        }
    }
}
@supports (-ms-ime-align:auto) {
    .link {
        &--underline {
            text-decoration: underline;
            & * {
                text-decoration: underline;
            }
            .no-touch &:hover,
            .no-touch &:hover * {
                text-decoration: none;
            }
        }

        &--normal {
            text-decoration: none;
            & * {
                text-decoration: none;
            }
            .no-touch &:hover,
            .no-touch &:hover * {
                text-decoration: underline;
            }
        }
    }
}
@media all and (-ms-high-contrast:none) {
    .link {
        &--underline {
            text-decoration: underline;
            & * {
                text-decoration: underline;
            }
            .no-touch &:hover,
            .no-touch &:hover * {
                text-decoration: none;
            }
        }

        &--normal {
            text-decoration: none;
            & * {
                text-decoration: none;
            }
            .no-touch &:hover,
            .no-touch &:hover * {
                text-decoration: underline;
            }
        }
    }
}