.arrow-button {
	color: $color-primary;
	font-size: 14px;
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	background-color: $color-light;
	transition: .3s ease;
	line-height: 1.25;
	padding: 0.85714285714285714285714285714286em 1.1428571428571428571428571428571em
		0.78571428571428571428571428571429em;
	&:after {
		content: "";
		width: 8px;
		height: 8px;
		border-top: 2px solid currentColor;
		border-right: 2px solid currentColor;
		transform-origin: center;
		transform: rotate(45deg);
		margin: 0 2px 0 5px;
		display: inline-block;
	}
	&--reversed {
		&:after {
			display: none;
		}
		&:before {
			content: "";
			width: 8px;
			height: 8px;
			border-top: 2px solid currentColor;
			border-left: 2px solid currentColor;
			transform-origin: center;
			transform: rotate(-45deg);
			margin: 0 5px 0 2px;
			display: inline-block;
		}
	}
	&--type-2 {
		background-color: $color-secondary;
		color: $color-default;
	}
	.no-touch &:hover {
		background-color: $color-primary;
		color: $color-light;
	}
}

.submit-button {
	color: $color-text;
	font-size: 1em;
	font-weight: 400;
	display: inline-block;
	text-transform: uppercase;
	transition: .3s ease;
	font-family: inherit;
	margin-top: 0.875em;
	.no-touch &:hover {
		color: $color-happy;
	}
}
