.contacts {
    &__content {
        padding: 2.5em 2em 3.125em;
        border: 0.125em solid $color-happy;
    }
    &__map {
        width: 100%;
        padding: 0 4.875em;
        margin-top: -1.125em;
        @media(max-width: $phablet) {
            padding: 0 5%;
        }
        &-container {
            height: 29.125em;
            position: relative;
            border: 1px solid $color-border;
            @media(max-width: $phone) {
                height: 0;
                padding-top: 130%;
            }
        }
    }
    &__heading {
        color: $color-dark;
        font-size: 1.625em;
        font-weight: 400;
        line-height: 1.3;
        max-width: 7.0769230769230769230769230769231em;
        text-align: right;
        @media(max-width: $phablet) {
            text-align: center;
            max-width: 100%;
            font-size: 1.4em;
        }
    }
    &-list {
        &__item {
            padding-bottom: 0.29411764705882352941176470588235em;
            &:last-child {
                padding-bottom: 0;
            }
            &-text {
                color: $color-primary;
                font-size: 1.0625em;
                font-weight: 400;
                line-height: 1.2;
            }
        }
    }
}

.gm-style .gm-style-iw-c,
.gm-style-iw-d {
    width: 200px !important;
    min-height: 0 !important;
    max-height: none !important;
    overflow: visible !important;
    max-width: 100% !important;
}

.infowindow {
    font-size: 12px;
    line-height: 1.4;
    padding: 1em 0;
    &__item {
        padding-bottom: 0.53846153846153846153846153846154em;
        &:last-child {
            padding-bottom: 0;
        }
        & a:not(.arrow-button) {
            display: inline-block;
        }
    }
    & .arrow-button {
        font-size: 0.92307692307692307692307692307692em;
    }

}

.google-map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}