.dropdown {
	&-container {
		position: relative;
	}
	&-button {
		position: relative;
		z-index: 12;
		&:after {
			content: "";
			position: absolute;
			width: 1em;
			height: 1em;
			background-color: $color-default;
			border-top: 1px solid $color-border;
			border-left: 1px solid $color-border;
			left: 50%;
			top: 100%;
			margin-top: 0.6875em;
			transform: translateX(-50%) translateY(10px) rotate(45deg);
			transition: .3s ease;
			opacity: 0;
			visibility: hidden;
		}
	}
	&-content {
		border: 1px solid $color-border;
		position: absolute;
		left: 50%;
		top: 100%;
		transform: translateX(-50%) translateY(10px);
		background-color: $color-default;
		margin-top: 1.1875em;
		transition: .3s ease;
		opacity: 0;
		visibility: hidden;
		z-index: 11;
		box-shadow: 0.3125rem 0.3125rem 0.625rem rgba(42, 42, 42, 0.05);
		&--right {
			left: auto;
			right: 0;
			transform: translateX(0) translateY(10px);
		}
	}
	&-container.active &-button:after,
	&-container.active &-content {
		opacity: 1;
		visibility: visible;
	}
	&-container.active &-button:after {
		transform: translateX(-50%) translateY(0) rotate(45deg);
	}
	&-container.active &-content {
		transform: translateX(-50%) translateY(0);
		&--right {
			transform: translateX(0) translateY(0);
		}
	}
}

.share-dropdown {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 17.625em;
	padding: 1.5em 2.625em;
	@media(max-width: $tablet) {
		padding: 1.5em;
		width: 100%;
	}
	&__item {
		padding-right: 0.9375em;
		flex-shrink: 0;
		&:last-child {
			padding-right: 0;
		}
	}
	&__link {
		display: inline-block;
		&-img {
			width: 28px;
			height: 28px;
		}
	}
}

.dropdown-form {
	padding: 1.5em;
	width: 22em;
	@media(max-width: $tablet) {
		width: 100%;
		padding: 1.5em 1em;
		.main-header__content-item & {
			width: 22em;
			@media(max-width: $phone) {
				width: 15em;
			}
		}
	}
}