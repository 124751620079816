.video {
    &-hexagon {
        fill: #f0b106;
        transition: .3s ease;
    }
    &-triangle {
        fill: $color-default;
    }
    &-section {
        background-color: $color-light;
        padding: 1.625em;
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            @media(max-width: $phone) {
                flex-wrap: wrap;
            }
        }
        &__hidden-content {
            padding-top: 1.625em;
            display: none;
        }
        &__text {
            color: $color-primary;
            font-size: 1.375em;
            font-weight: 400;
            text-align: left;
            @media(max-width: $phone) {
                flex-basis: 100%;
                max-width: 100%;
                flex-grow: 1;
                flex-shrink: 1;
                text-align: center;

            }
        }
        &__button {
            margin-left: 1.3125em;
            @media(max-width: $phone) {
               margin: 1.3125em 0 0 0;
            }
            &-icon {
                display: inline-block;
                width: 4.3125em;
                height: 4.9375em;
                overflow: hidden;
            }
            .no-touch &:hover .video-hexagon {
                fill: $color-primary;
            }
        }
    }
}