.sticky-nav {
	position: relative;
	z-index: 10;
	&__button {
		font-size: 1em;
		width: 3.75em;
		height: 3.75em;
		background-color: $color-danger;
		transition: .3s ease;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		@media(max-width: $phone) {
			font-size: .7em;
		}
		.no-touch &:hover {
			background-color: $color-happy;
		}
		&-icon {
			position: relative;
			& .icon {
				width: 1.625em;
				height: 1.5625em;
				fill: $color-default;
			}
		}
		&-text {
			color: $color-default;
			font-size: 0.875em;
			font-weight: 400;
			width: 1.5714285714285714285714285714286em;
			height: 1.5714285714285714285714285714286em;
			border-radius: 50%;
			background-color: $color-primary;
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(50%, -50%);
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			font-style: normal;
		}
	}
	&.active &__button {
		transform: translateY(-50%) translateX(100%);
	}
	&__container {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		transform: translateY(100%);
		opacity: 0;
		visibility: hidden;
		transition: .3s ease;
	}
	&.active &__container {
		transform: translateY(0);
		opacity: 1;
		visibility: visible;
	}
	&-content {
		display: flex;
		flex-wrap: wrap;
		box-shadow: 0px 2px 15px rgba($color-dark, .1);
		background-color: $color-default;
		position: relative;
		&__close-button {
			display: inline-block;
			position: absolute;
			top: 5px;
			right: 9px;
			margin-right: -2px;
			margin-top: -2px;
			padding: 5px;
			font-size: 0;
			& .icon {
				width: 13px;
				height: 13px;
				fill: $color-dark;
				transition: .3s ease;
			}
			.no-touch &:hover .icon {
				fill: $color-danger;
			}
		}
		&__item {
			flex-basis: 50%;
			max-width: 100%;
			flex-grow: 1;
			flex-shrink: 1;
			border-top: 2px solid $color-danger;
			&:nth-of-type(1),
			&:nth-of-type(2) {
				border-top: none;
			}
			@media(max-width: $phone) {
				flex-basis: 100%;
				&:nth-of-type(2) {
					border-top: 2px solid $color-danger;
				}
			}
		}
		&__link {
			display: block;
			display: flex;
			height: 100%;
			@media(max-width: $tablet) {
				font-size: 14px;
			}
			@media(max-width: $phablet) {
				font-size: 12px;
			}
			&-icon {
				width: 3.875em;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				flex-grow: 0;
				background-color: $color-danger;
				transition: .3s ease;
				& .icon {
					width: 2em;
					height: 1.9375em;
					fill: $color-default;
				}
			}
			.no-touch &:hover &-icon {
				background-color: $color-primary;
			}
			&-content {
				display: flex;
				align-items: center;
				padding: 2.125em 1.4375em 2em;
			}
			&-text {
				color: $color-primary;
				font-size: 1.125em;
				font-weight: 400;
				display: inline-flex;
				align-items: center;
				&:after {
					content: "";
					background-image: url(../images/icons/arrow-right-blue.svg);
					background-repeat: no-repeat;
					background-size: contain;
					width: 9px;
					height: 16px;
					margin-left: 0.625em;
					transition: .3s ease;
					flex-shrink: 0;
				}
			}
			.no-touch &:hover &-text {
				&:after {
					transform: translateX(5px);
				}
			}
		}
	}
}