.pagination {
    font-size: 16px;
    display: flex;
    justify-content: center;
    padding-top: 2.625em;
    @media(max-width: $phone) {
        padding-top: 2em;
    }
    &__nav {
        display: flex;
        align-items: center;
    }
    &__button {
        &--prev,
        &--next {
            color: $color-text;
            font-size: 1em;
            font-weight: 400;
            line-height: 1;
            display: flex;
            align-items: center;
            padding: 0 5px;
            @media(max-width: $tablet) {
                font-size: 1.5em;
            }
            @media(max-width: $phone) {
                font-size: 1.3em;
            }
        }
        &--prev {
            margin-right: 2.5em;
            @media(max-width: $tablet) {
                margin-right: 0.5em;
            }
            &:before {
                content: "<";
                margin-right: 0.375em;
                transition: transform .3s ease;
                @media(max-width: $tablet) {
                    margin-right: 0;
                }
            }
            .no-touch &:hover:before {
                transform: translateX(-3px);
            }
        }
        &--next {
            margin-left: 2.5em;
            @media(max-width: $tablet) {
                margin-left: 0.5em;
            }
            &:after {
                content: ">";
                margin-left: 0.375em;
                transition: transform .3s ease;
                @media(max-width: $tablet) {
                    margin-left: 0;
                }
            }
            .no-touch &:hover:after {
                transform: translateX(3px);
            }
        }
        &-text {
            @media(max-width: $tablet) {
                display: none;
            }
        }
    }
    &__container {
        display: flex;
        align-items: center;
    }
    &__container &__button {
        font-size: 1.125em;
        width: 2.222222222222222em;
        height: 2.222222222222222em;
        display: flex;
        line-height: 1;
        align-items: center;
        justify-content: center;
        color: $color-text;
        border: 1px solid $color-light;
        background-color: $color-light;
        margin-right: 0.6111111111111111em;
        transition: .3s ease;
        @media(max-width: $phone) {
            font-size: 1em;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active,
        .no-touch &:hover {
            background-color: $color-default;
            color: $color-primary;
            border-color: #979797;
        }
    }
}