.tabs {
    &__nav {
        margin-bottom: -2px;
        &-select {
            display: none;
            @media(max-width: $laptop) {
                display: block;
            }
            & .site-form__select {
                border-color: $color-happy;
            }
        }
        &-buttons {
            display: flex;
            @media(max-width: $laptop) {
                display: none;
            }
        }
        &-button {
            color: $color-primary;
            font-family: "Roboto Condensed";
            font-size: 1.0625em;
            font-weight: 400;
            line-height: 1.3;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0.58823529411764705882352941176471em 2.3529411764705882352941176470588em;
            border: 2px solid transparent;
            position: relative;
            flex-grow: 1;
            flex-shrink: 1;
            min-height: 2.6em;
            transition: .3s ease;
            &:last-child {
                margin-right: 0;
                // margin-right: -0.5px;
            }
            &:nth-last-child(1):nth-child(odd) {
                margin-right: -0.5px;
            }
            &:after {
                content: "";
                font-size: 0;
                min-height: inherit;
            }
            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -2px;
                height: 2px;
                background-color: transparent;
                transition: .3s ease;
            }
            .no-touch &:hover {
                color: $color-happy;
            }
            &.active {
                border-color: $color-happy;
                &:before {
                    background-color: $color-default;
                }
            }
        }
    }
    &__content {
        border: 2px solid $color-happy;
        padding: 2.25em 2.875em;
        @media(max-width: $phablet) {
            padding: 1.5em;
        }
        &-item {
            display: none;
            &.active {
                display: block;
            }
        }
    }
}