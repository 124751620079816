.animation {
    transition: .7s ease;
    opacity: 0;
    visibility: hidden;
    .aos-animate & {
        opacity: 1;
        visibility: visible
    }
    &--delay {
        transition-delay: .3s;
        &-2 {
            transition-delay: .6s;
        }
        &-3 {
            transition-delay: 1s;
        }
    }
    &--fade-up {
        transform: translateY(25%);
        .aos-animate & {
            transform: translateY(0);
        }
    }
    &--fade-down {
        transform: translateY(-25%);
        .aos-animate & {
            transform: translateY(0);
        }
    }
    &--fade-left {
        transform: translateX(-25%);
        .aos-animate & {
            transform: translateX(0);
        }
    }
    &--fade-right {
        transform: translateX(25%);
        .aos-animate & {
            transform: translateX(0);
        }
    }
}