.homepage {
	&-main-section {
		position: relative;
		overflow: hidden;
		color: $color-default;
		&__background {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
		&__content {
			min-height: 19.75em;
			display: flex;
			flex-wrap: wrap;
			padding: 3.125em 0 2.5em;
			position: relative;
			@media (max-width: $laptop) {
				font-size: calc(10px + (16 - 10) * ((100vw - 320px) / (1024 - 320)));
			}
			&:after {
				content: "";
				font-size: 0;
				min-height: inherit;
			}
		}
		&__heading {
			max-width: 100%;
			flex-basis: 100%;
			flex-grow: 1;
			flex-shrink: 1;
			font-size: 3em;
			font-weight: 400;
			padding-bottom: .75em;
			@media (max-width: $phablet) {
				text-align: center;
			}
		}
	}
	&-link-list {
		max-width: 100%;
		flex-basis: 100%;
		flex-grow: 1;
		flex-shrink: 1;
		margin-top: auto;
		display: flex;
		align-items: flex-end;
		@media (max-width: $laptop) {
			padding-right: 0;
		}
		&:after {
			content: "";
			font-size: 0;
			min-height: inherit;
		}
		&__item {
			display: inline-block;
			@media (max-width: $tablet) {
				font-size: calc(8px + (12 - 8) * ((100vw - 320px) / (768 - 320)));
			}
			&-text {
				display: block;
				&--big {
					font-size: 3.875em;
					font-weight: 400;
					position: relative;
					margin-bottom: -0.03225806451612903225806451612903em;
					display: flex;
					align-items: flex-start;
					line-height: 1;
					padding-right: 10px;
					&:after {
						content: "";
						background-image: url(../images/arrow-right.svg);
						background-repeat: no-repeat;
						background-size: contain;
						width: 0.25806451612903225806451612903226em;
						height: 0.41935483870967741935483870967742em;
						transition: .3s ease;
						margin-left: 0.24193548387096774193548387096774em;
						margin-top: 0.35483870967741935483870967741935em;
					}
				}
				&--small {
					font-size: 16px;
					font-weight: 400;
					@media (max-width: $phone) {
						font-size: 13px;
					}
				}
			}
			.no-touch &:hover &-text--big:after {
				transform: translateX(10px);
			}
		}
	}
}
@media all and (-ms-high-contrast: none) {
	.homepage-main-section__content {
		height: 513px;
	}
	.top-header__container {
		height: 240px;
	}
}
