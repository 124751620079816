.gallery {
	display: block;
	padding: 1.5em 0;
	&__link {
		display: inline-block;
		background-color: $color-dropdown;
		border: 1px solid $color-dropdown-border;
		padding: 1em;
		max-width: 334px;
		transition: .3s ease;
		&-img {
			&-holder {
				width: 100%;
				display: block;
				position: relative;
				&:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: rgba(255, 255, 255, .6);
					background-image: url(../images/icons/search.svg);
					background-repeat: no-repeat;
					background-size: 20%;
					background-position: center center;
					opacity: 0;
					visibility: hidden;
					transition: .5s ease;
				}
			}
			width: 100%;
		}
		&-text {
			display: block;
			text-align: center;
			padding-top: 1em;
			font-size: 1em;
		}
		.no-touch &:hover &-img-holder:before {
			opacity: 1;
			visibility: visible;
		}
	}
}
