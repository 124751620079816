/* text - typography */

.text {
    font-size: 1em;
    // overflow: hidden;
    color: $color-dark;
    & > *:last-child {
        margin-bottom: 0 !important;
    }
    small {
        font-size: 0.75em;
    }
    a,
    span,
    strong {
        display: inline;
    }
    a {
        transition: 0.3s ease;
        text-decoration: underline;
        text-decoration-color: transparent;
        color: $color-happy;

        .no-touch &:hover {
            text-decoration-color: currentcolor;
        }
    }
    p {
        font-size: 1.0625em;
        line-height: 1.5;
        margin-bottom: 1.411764705882353em;
    }
    /* Titles styles */
    h2 {
        font-size: 2em;
        margin-bottom: 0.625em;
    }
    h3 {
        font-size: 1.875em;
        margin-bottom: 0.6666666666666667em;
    }
    h4 {
        font-size: 1.5em;
        margin-bottom: 0.83333333333333333333333333333333em;
    }
    h5 {
        font-size: 1.375em;
        margin-bottom: 0.9090909090909091em;
    }
    h6 {
        font-size: 1.25em;
        margin-bottom: 1em;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
        color: $color-dark;
        line-height: 1.3;
    }
    h2,
    h4,
    h6 {
        color: $color-text;
    }
    /* image styles */
    img {
        &[style*="float: left"],
        &[style*="float:left"] {
            margin: 1.25em 1.25em 1.25em 0;
            @media (max-width: $laptop) {
                max-width: 50%;
            }
            @media (max-width: $phone) {
                max-width: 100%;
                float: none !important;
                margin: 1.25em 0;
                display: block;
            }
        }
        &[style*="float: right"],
        &[style*="float:right"] {
            margin: 1.25em 0 1.25em 1.25em;
            @media (max-width: $laptop) {
                max-width: 50%;
            }
            @media (max-width: $phone) {
                max-width: 100%;
                float: none !important;
                margin: 1.25em 0;
                display: block;
            }
        }
    }

    & > ul,
    & > ol {
        font-size: 1.0625em;
        margin-bottom: 1.875em;
        padding-left: 2.352941176470588em;
        @media (max-width: $phablet) {
            padding-left: 0;
        }
    }
    li {
        line-height: 1.4;
        padding-bottom: 0.5882352941176471em;
        color: $color-text;
        &:last-child {
            padding-bottom: 0;
        }
    }
    ol {
        counter-reset: section;
        list-style-type: none;
        li {
            &:before {
                counter-increment: section;
                content: counters(section, ".") ".";
                margin-right: 0.2941176470588235em;
                color: $color-secondary;
            }
        }
        ul,
        ol {
            padding-left: 1.529411764705882em;
            padding-top: 0.5882352941176471em;
        }
    }
    ul {
        ol,
        ul {
            padding-top: 0.5882352941176471em;
        }
        li {
            padding-left: 1.529411764705882em;
            position: relative;
            &:before {
                content: "";
                width: 7px;
                height: 7px;
                background-color: $color-secondary;
                display: inline-block;
                position: absolute;
                border-radius: 50%;
                left: 0;
                top: 0.5294117647058824em;
            }
        }
    }

    /* Table styles  */
    table {
        border-collapse: collapse;
        width: 100%;
        color: $color-dark;
        @media (max-width: $tablet) {
            font-size: 15px;
        }
        @media (max-width: $phone) {
            font-size: 14px;
        }
        thead {
            border-bottom: 2px solid $color-happy;
        }
        th {
            color: $color-primary;
            font-size: 1em;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            padding: 0 0.625em 0.8125em;
        }
        td {
            color: $color-primary;
            font-size: 0.9375em;
            font-weight: 400;
            padding: 1.4666666666666666666666666666667em
                0.93333333333333333333333333333333em;
            text-align: center;
        }
        tr {
            border-top: 1px solid $color-border;
            &:first-child {
                border-top: none;
            }
            &.row-empty {
                height: 1.875em;
                & td {
                    padding: 0 !important;
                    border: none !important;
                }
            }
            &.row-color {
                & td {
                    padding-top: 1.4em;
                    padding-bottom: 1.4em;
                }
                &--1 {
                    background-color: $color-row-1;
                    & td {
                        padding-top: 0.93333333333333333333333333333333em;
                        padding-bottom: 0.93333333333333333333333333333333em;
                    }
                }
                &--2 {
                    background-color: $color-row-2;
                }
            }
        }
        &.fixed-layout {
            table-layout: fixed;
        }
        &.border-r-first tr td:first-child {
            border-right: 1px solid $color-border;
            text-align: left;
        }
        &.border-r-not-last tr td {
            border-right: 1px solid $color-border;
            &:last-child {
                border-right: none;
            }
        }
        &.border-b tbody tr:last-child {
            border-bottom: 1px solid $color-border;
        }
        &.custom {
            & td,
            & th {
                text-transform: none;
            }
        }
    }

    /* blockquote */
    blockquote {
        color: $color-text;
        font-size: 1.0625em;
        font-weight: 400;
        line-height: 1.5;
        padding: 2em 1.764705882352941em;
        border: 1px solid $color-secondary;
        margin-bottom: 3em;
        @media (max-width: $phone) {
            padding: 1.5em;
            margin-bottom: 1.5em;
        }
    }
    &--mb-s {
        p {
            margin-bottom: 0.4em;
        }
    }
}

@supports (-ms-ime-align: auto) {
    .text {
        a {
            text-decoration: none;
            .no-touch &:hover {
                text-decoration: underline;
            }
        }
    }
}
@media all and (-ms-high-contrast: none) {
    .text {
        a {
            text-decoration: none;
            .no-touch &:hover {
                text-decoration: underline;
            }
        }
    }
}
/* end - typography */
.scrollable-content {
    overflow: auto;
    font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 30px;
    & table {
        @media (max-width: $tablet) {
            width: $tablet;
        }
    }
    .touch &::-webkit-scrollbar {
        display: none;
    }
}
