.slider {
    margin: 0 -2rem;
    &-container {
        overflow: hidden;
    }
    &__item {
        padding: 0 2rem;
    }
    & .slick-dots {
        padding: 2rem 2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        & li {
            width: .5rem;
            height: .5rem;
            text-indent: -9999px;
            overflow: hidden;
            background-color: $color-text;
            margin-right: 1.125rem;
            transition: .3s ease;
            border-radius: 50%;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            &.slick-active,
            .no-touch &:hover {
                background-color: $color-quaternary;
            }
        }
    }
}