.site-map {
    &__item {
        position: relative;
        padding-bottom: 1.625em;
        padding-left: 3.75em;
        @media(max-width: $phone) {
            padding-left: 2em;
        }
        &:before {
            content: "";
            display: inline-block;
            width: 11px;
            height: 11px;
            border: 2px solid $color-primary;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0.5em;
            background-color: $color-default;
            z-index: 2;
        }
        &:after {
            content: "";
            display: inline-block;
            top: 1.125em;
            bottom: -0.5em;
            left: 0.3125em;
            width: 1px;
            position: absolute;
            background-color: rgba($color-dark, .3);
            z-index: 1;
        }
        &:last-child {
            padding-bottom: 0;
            &:after {
                display: none;
            }
        }
    }
    &__heading {
        color: $color-primary;
        font-size: 1.25em;
        font-weight: 400;
        line-height: 1.3;
        margin-bottom: .5em;
    }
    &__list {
        &-item {
            position: relative;
            padding-left: 1.0625em;
            padding-bottom: 0.625em;
            &:last-child {
                padding-bottom: 0;
            }
            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 5px;
                height: 5px;
                background-color: $color-happy;
                border-radius: 50%;
                left: 0;
                top: 0.5em;
            }
        }
        &-link {
            color: $color-text;
            font-size: 1.0625em;
            font-weight: 400;
            line-height: 1.3;
        }
    }
    &__list &__list {
        padding-top: 0.625em;
    }
}