.printable-content {
    font-size: 16px;
    @media(max-width: $tablet) {
        font-size: 15px;
    }
    @media(max-width: $phone) {
        font-size: 14px;
    }
}

/* Print styles */
@media print {
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
        page-break-after: avoid;
    }
    a[href]:empty::before {
        content: attr(href);
    }
    .main-header__content,
    .page-controller-container__content,
    .arrow-button,
    .main-footer,
    .site-message,
    .sticky-nav,
    .site-form__item,
    .accordion__decoration,
    .pagination {
        display: none !important;
    }
    * {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }
    .documents__list-item,
    .cascade-list__item,
    .accent,
    .lined-list__heading,
    .dictionary-list,
    .dictionary-box-content__item,
    .section-list,
    blockquote,
    p {
        page-break-inside: avoid;
    }
    .scrollable-content {
        page-break-inside: avoid;
    }
}