.dictionary {
	&__nav {
		padding-bottom: 2.5em;
		&-item {
			display: inline-block;
			transition: .3s ease;
			color: $color-dark;
			font-size: 1.375em;
			font-weight: 400;
			text-transform: uppercase;
			.no-touch &:hover {
				color: $color-happy;
			}
			&:disabled {
				color: $color-disabled;
				cursor: not-allowed;
			}
		}
	}
	&-box {
		&__item {
			padding-bottom: 1.8em;
			&:last-child {
				padding-bottom: 0;
			}
		}
		&__heading {
			color: $color-secondary;
			font-size: 3.125em;
			font-weight: 400;
			display: inline-block;
			line-height: .7;
			text-align: left;
			min-width: 1.6em;
			border-bottom: 1px solid $color-border;
			padding-bottom: 0.32em;
			margin-bottom: 0.6em;
		}
		&-content {
			padding-left: 2.5625em;
			@media (max-width: $phone) {
				padding-left: 0;
			}
			&__item {
				padding-bottom: 1.25em;
				&:last-child {
					padding-bottom: 0;
				}
			}
			&__title {
				display: block;
				color: $color-dark;
				font-size: 1.375em;
				font-weight: 400;
				line-height: 1.3;
				margin-bottom: .5em;
				&:last-child {
					margin-bottom: 0;
				}
			}
			&__subtitle {
				color: $color-text;
				font-size: 1.125rem;
				padding-bottom: 0.27777777777777777777777777777778em;
				font-weight: 400;
				line-height: 1.4;
				display: block;
			}
			&__field {
				padding-left: 1.375em;
				@media (max-width: $phone) {
					padding-left: 0;
				}
				& .text {
					& h1,
					& h2,
					& h3,
					& h4,
					& h5,
					& h6,
					& p {
						font-size: 1.125em;
						font-weight: 400;
						line-height: 1.4;
					}
					& ul {
						padding-left: 1em;
					}
				}
			}
		}
	}
	&-list {
		&__item {
			padding-left: 0.6875em;
			padding-bottom: 0.29411764705882352941176470588235em;
			position: relative;
			color: $color-text;
			font-size: 1.0625rem;
			line-height: 1.4;
			&:last-child {
				padding-bottom: 0;
			}
			& a {
				color: $color-happy;
			}
		}
		&--dotted &__item {
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0.58823529411764705882352941176471em;
				width: 0.23529411764705882352941176470588em;
				height: 0.23529411764705882352941176470588em;
				display: inline-block;
				border-radius: 50%;
				background-color: $color-secondary;
			}
		}
		&--dashed &__item {
			&:before {
				content: "-";
				margin-right: 0.29411764705882352941176470588235em;
			}
		}
	}
}
