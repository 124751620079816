.info-list {
	&__item {
		text-align: center;
	}
	&__img {
		padding-bottom: 2em;
		max-width: 250px;
		max-height: 240px;
		height: auto;
		width: auto;
	}
	&__accent {
		display: flex;
		align-items: baseline;
		justify-content: center;
		padding-bottom: 1em;
		@media (max-width: $phone) {
			font-size: 10px;
		}
		&:last-child {
			padding-bottom: 0;
		}
		&-text {
			color: $color-text;
			font-size: 1.875em;
			font-weight: 400;
			line-height: 1.1;
			display: inline-block;
			&--big {
				font-weight: 300;
				font-size: 4.375em;
				line-height: 0.47;
				padding: 0 .1em;
			}
		}
	}
	&__note {
		color: $color-text;
		font-size: 1.125em;
		font-weight: 400;
		text-align: center;
		@media (max-width: $phone) {
			font-size: 1.0625em;
		}
	}
}
