.error-404 {
	padding-top: 5em;
	text-align: center;
	@media(max-width: $tablet) {
		padding-top: 3em;
	}
	@media(max-width: $phone) {
		padding-top: 1em;
	}
	&__content {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		padding-bottom: 3.875em;
		@media(max-width: $tablet) {
			font-size: 2.3vw;
		}
	}
	&__text {
		color: $color-dark;
		font-size: 1.625em;
		font-weight: 400;
		line-height: 1.3;
		max-width: 7.0769230769230769230769230769231em;
		text-align: right;
		display: inline-block;
		margin-right: 1.6153846153846153846153846153846em;
		@media(max-width: $phone) {
			font-size: 2em;
		}
	}
	&__title {
		display: inline-block;
		font-size: 16em;
		font-weight: 300;
		line-height: .72;
		color: $color-secondary;
		@media(max-width: $phone) {
			font-size: 14em;
		}
	}
}