.search-results {
	&__item {
		padding-bottom: 2.25em;
		&:last-child {
			padding-bottom: 0;
		}
	}
	&__title {
		color: $color-dark;
		font-size: 1.375em;
		font-weight: 400;
		display: block;
		width: 100%;
		padding-bottom: 0.77272727272727272727272727272727em;
	}
	&__text {
		color: $color-text;
		font-size: 1.0625em;
		font-weight: 400;
		line-height: 1.5;
		margin-bottom: 1.6470588235294117647058823529412em;
		max-height: 4.5em;
		overflow: hidden;
	}
	&__keyword {
		color: $color-danger;
	}
}
