.documents {
	&__list {
		&-item {
			position: relative;
			z-index: 1;
			margin-bottom: -1px;
			&:last-child {
				margin-bottom: 0;
			}
			.no-touch &:hover {
				z-index: 2;
			}
		}
	}
	&__link {
		border: 1px solid $color-border;
		transition: .3s ease;
		padding: 1.125em 0.9375em 1.0625em;
		display: flex;
		width: 100%;
		align-items: center;
		color: $color-primary;
		position: relative;
		@media(max-width: $phone) {
			font-size: 14px;
		}
		.no-touch &:hover {
			border-color: $color-primary;
		}
		&:after {
			content: "";
			background-image: url(../images/icons/download.svg);
			background-repeat: no-repeat;
			background-size: contain;
			width: 31px;
			height: 26px;
			margin-left: 1em;
			display: inline-block;
			@media(max-width: $phone) {
				width: 20px;
				height: 16px;
			}
		}
		&-text {
			display: flex;
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 100%;
			max-width: 100%;
			position: relative;
			align-items: center;
			line-height: 1.3;
			&:before {
				content: "";
				background-image: url(../images/icons/document.svg);
				background-repeat: no-repeat;
				background-size: contain;
				width: 30px;
				height: 35px;
				display: inline-block;
				margin-right: 1.8125em;
				flex-shrink: 0;
				@media(max-width: $phone) {
					margin-right: 1.3em;
				}
			}
			&:after {
				content: "file";
				color: $color-secondary;
				font-size: 14px;
				font-weight: 400;
				text-transform: uppercase;
				position: absolute;
				left: 13px;
				top: 50%;
				transform: translateY(-50%);
				margin-top: 6px;
				@media(max-width: $phone) {
					margin-top: 4px;
				}
			}
		}
		&[href$= ".doc"] &-text:after,
		&[href$= ".DOC"] &-text:after,
		&[href$= ".docx"] &-text:after,
		&[href$= ".DOCX"] &-text:after {
			content: "doc";
		}
		&[href$= ".odt"] &-text:after,
		&[href$= ".ODT"] &-text:after {
			content: "odt";
		}
		&[href$= ".xls"] &-text:after,
		&[href$= ".XLS"] &-text:after,
		&[href$= ".xlsx"] &-text:after,
		&[href$= ".XLSX"] &-text:after {
			content: "xls";
		}
		&[href$= ".ods"] &-text:after,
		&[href$= ".ODS"] &-text:after {
			content: "ods";
		}
		&[href$= ".ppt"] &-text:after,
		&[href$= ".PPT"] &-text:after,
		&[href$= ".PPTX"] &-text:after,
		&[href$= ".pptx"] &-text:after {
			content: "ppt";
		}
		&[href$= ".txt"] &-text:after,
		&[href$= ".TXT"] &-text:after {
			content: "txt";
		}
		&[href$= ".pdf"] &-text:after,
		&[href$= ".PDF"] &-text:after {
			content: "pdf";
		}
	}
}